import type { GetStaticProps, NextPage } from "next";
import Head from "next/head";
import Image from "next/image";
import { useEffect, useState } from "react";
import { AutoSwiper } from "../components/lp/swiper/AutoSwiper";
import styles from "../styles/Home.module.scss";

import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { useRouter } from "next/router";
import NewsListSwipe from "../components/news/NewsListSwipe";

import { useRecoilState } from "recoil";
import { languageState } from "../store/language";

import Link from "next/link";
import { Transition } from "react-transition-group";
import { useMedia } from "react-use";
import VerticalDots from "../components/common/ui/VerticalDots";
import { MOBILE_MAX_WIDTH } from "../constant";
import { useTranslation } from "../services/i18n";
import { fetchNewsList } from "../services/news";
import { NewsArray } from "../types/newsType";

type Props = {
  contents: NewsArray;
};

export const getStaticProps: GetStaticProps<Props> = async () => {
  const data = await fetchNewsList({ limit: 3 });

  return {
    props: {
      contents: data?.contents ?? [],
    },
  };
};

const Home: NextPage<Props> = ({ contents: newsArray }: Props) => {
  const t = useTranslation();

  // mq
  const isMobile = useMedia(`(max-width: ${MOBILE_MAX_WIDTH}px)`, false);

  const items = [...Array(6)].map((_i, index) => {
    return { src: `/images/lp/items/${index}.jpg`, text: "GENE N_2342" };
  });

  const [language, setLanguage] = useRecoilState(languageState);
  const [isJapanese, setIsJapanese] = useState(false);
  const router = useRouter();
  const [ogUrl, setOgUrl] = useState("");
  const [currentUrl, setCurrentUrl] = useState("");

  const [initialSwiperIndex, setInitialSwiperIndex] = useState(1);

  const [swiper, setSwiper] = useState<any>(null);
  const swiperParams = {
    initialSlide: initialSwiperIndex,
    onSwiper: setSwiper,
    dir: "ltl",
    spaceBetween: 50,
    slidesPerView: 1,
    navigation: {
      prevEl: "#about_swipe_button_prev",
      nextEl: "#about_swipe_button_next",
    },
    pagination: {
      clickable: true,
      el: "#about_pagination",
    },
    loop: true,
    className: `${isMobile ? `w-full` : `max-w-[1221px]`} h-full`,
    modules: [Navigation, Pagination],
  };
  const windowHref = typeof window !== "undefined" ? window.location.href : "";

  useEffect(() => {
    if (windowHref.includes("?about")) {
      setInitialSwiperIndex(1);
    } else if (windowHref.includes("?world")) {
      setInitialSwiperIndex(2);
    } else if (windowHref.includes("?what")) {
      setInitialSwiperIndex(3);
    }
  }, [windowHref]);

  useEffect(() => {
    if (swiper !== null) {
      swiper.slideTo(initialSwiperIndex);
    }
  }, [swiper, initialSwiperIndex]);

  useEffect(() => {
    const origin = window.location.origin;
    const href = window.location.href;

    setOgUrl(`${origin}/ogp/image.jpg`);
    setCurrentUrl(href);
  }, [router.pathname]);
  // 言語切り替え
  useEffect(() => {
    setIsJapanese(language === "ja");
  }, [language]);

  // TODO: 仮　mp4 Init play
  const [isVideo, setIsVideo] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVideo(true);
      clearTimeout(timer);
    }, 2 * 1000);
  });

  return (
    <div className={styles.container}>
      <Head>
        <title>NEN</title>
        <meta
          property="og:image"
          content="https://www.pjnen.com/ogp/image.jpg"
        />
        <meta property="og:url" content="https://www.pjnen.com/" />
      </Head>

      {/** background */}
      <div className={styles.background} />

      {/** KEY VISUAL */}
      <section className={styles.main}>
        <div className="overflow-y-hidden relative">
          <div className="absolute z-[8] top-0 w-full h-full bg-[url('/images/noise.gif')] opacity-10" />
          <div
            className={`absolute z-10 top-0 left-1/2 -translate-x-1/2 ${
              isMobile ? "w-[60%]" : "max-w-[31%]"
            }`}
          >
            <Image
              src="/images/nen-char.png"
              alt="nen"
              width={600}
              height={1080}
            />
          </div>
          <div
            className={`absolute z-[5] bottom-[14%] left-[2.5%] ${
              isMobile ? "max-w-[52%]" : "max-w-[30%]"
            }`}
          >
            <Image
              src="/images/kv-logo.png"
              alt="nascent logo"
              width={659}
              height={132}
            />
          </div>
          <div className="-z-10">
            {isMobile ? (
              <Image
                src="/images/sp-kv-bg.png"
                alt="NEURAL ELECTIVE N"
                width={1125}
                height={1221}
              />
            ) : (
              <Image
                src="/images/pc-kv-bg.jpg"
                alt="NEURAL ELECTIVE N"
                width={3840}
                height={2160}
              />
            )}
          </div>
        </div>
      </section>

      {/** ABOUT PROJECT */}
      <div id="about" className={styles["about-wrapper"]}>
        <div className={styles["video-top-black-rect"]} />
        <div
          className={
            "bg-black absolute top-0 w-full z-10 " +
            (isMobile ? "pt-[30px] pb-[20.2px]" : "pt-[50px] pb-[11.2px]")
          }
        >
          <VerticalDots />
        </div>
        <GradientRect extraClass={isMobile ? "top-[110px]" : "top-[50px]"} />
        <GradientRect extraClass={isMobile ? "bottom-[10px]" : "bottom-0"} />
        <div className={styles[`video-background`]}>
          {isVideo && (
            <video
              preload="none"
              className={styles[`pr-video`]}
              autoPlay
              loop
              muted
              playsInline
            >
              <source src="/video/top_pr_movie.mp4" type="video/mp4" />
            </video>
          )}
        </div>
        <div className={styles[`slider__wrapper`]}>
          <Swiper {...swiperParams}>
            <span slot="container-start" />
            <div className="slider_wrapper">
              <span slot="wrapper-start" />
              <SwiperSlide>
                <div className={styles["slide-contents-wrapper"]}>
                  {isMobile ? (
                    <Image
                      src={"/images/text_images/about_project_sp.png"}
                      alt="About Project"
                      width={311}
                      height={130}
                    />
                  ) : (
                    <Image
                      src={"/images/text_images/about_project.png"}
                      alt="About Project"
                      width={851}
                      height={201}
                    />
                  )}
                  <h3>{t("aboutProjectHeading")}</h3>
                  <div className={styles["slide-contents-p-wrapper"]}>
                    <p>{t("aboutProjectContents")}</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles["slide-contents-wrapper"]}>
                  {isMobile ? (
                    <Image
                      src={"/images/text_images/world.png"}
                      alt="World"
                      width={311}
                      height={73}
                    />
                  ) : (
                    <Image
                      src={"/images/text_images/world.png"}
                      alt="World"
                      width={851}
                      height={201}
                    />
                  )}
                  <h3>{t("worldHeading")}</h3>
                  <div
                    className={
                      styles["slide-contents-p-wrapper"] +
                      " " +
                      styles["no-padding"]
                    }
                  >
                    <p>{t("worldContents")}</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles["slide-contents-wrapper"]}>
                  {isMobile ? (
                    <Image
                      src={"/images/text_images/what_is_ai_sp.png"}
                      alt="What Is AI N"
                      width={311}
                      height={108}
                    />
                  ) : (
                    <Image
                      src={"/images/text_images/what_is_ai.png"}
                      alt="What Is AI N"
                      width={851}
                      height={201}
                    />
                  )}
                  <h3>{t("whatIsAiNHeading")}</h3>
                  <div className={styles["slide-contents-p-wrapper"]}>
                    <p>{t("whatIsAiNContents")}</p>
                  </div>
                </div>
              </SwiperSlide>
              <span slot="wrapper-end" />
              <span slot="container-end" />
            </div>
          </Swiper>

          <div
            className={`${styles["swiper-button-prev"]} swiper-button-prev`}
            id="about_swipe_button_prev"
          >
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              className={styles["about-swiper-button-img"]}
              src="/images/button_images/arrow_left.png"
              alt="N About Left Allow Button"
            />
          </div>
          <div
            className={`${styles["swiper-button-next"]} swiper-button-next`}
            id="about_swipe_button_next"
          >
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              className={`${styles["about-swiper-button-img"]}`}
              src="/images/button_images/arrow_right.png"
              alt="N About Left Allow Button"
            />
          </div>

          <div
            className={`${styles[`about-swiper-pagination`]} swiper-pagination`}
            id="about_pagination"
          />
        </div>
      </div>

      {/** NEWS */}
      {newsArray.length ? (
        <>
          <NewsListSwipe newsArray={newsArray} />
        </>
      ) : (
        <>
          <div className={styles["no-news"]}>No News</div>
        </>
      )}

      {/** Utility PC*/}
      {!isMobile && (
        <section id="utility" className="mt-[129px] mx-auto max-w-[1334px]">
          <div className={`text-center`}>
            <Image
              src={"/images/text_images/nft_utility.png"}
              alt="Utility Font"
              width={701}
              height={141}
            />
          </div>
          <div className="flex flex-row gap-[52px] mt-[31px]">
            <UtilityImg />
            <div className="flex flex-col max-w-[746px]">
              <div className={styles["nft-utility-heading-wrapper"]}>
                <Image
                  src={"/images/decoration/utility_frame.png"}
                  alt="Utility Thumb"
                  width={746}
                  height={66}
                />
                <h3
                  className={`${styles["nft-utility-heading"]} ${
                    isJapanese ? styles["is-japanese"] : ""
                  }
                  }`}
                >
                  {t("utilityHeading")}
                </h3>
              </div>
              <p className={styles["nft-utility-contents"]}>
                {t("utilityContents")}
              </p>
            </div>
          </div>
        </section>
      )}

      {/** Utility Mobile */}
      {isMobile && (
        <section id="utility" className="w-full mt-[90px] mx-auto">
          <div className={`text-center`}>
            <Image
              src={"/images/text_images/nft_utility_sp.png"}
              alt="Utility Font"
              width={216}
              height={142}
            />
          </div>
          <div className={styles["nft-utility-heading-wrapper"]}>
            <Image
              src={"/images/decoration/utility_frame_sp.png"}
              alt="Utility Frame"
              width={343}
              height={isJapanese ? 64 : 88}
            />
            <h3
              className={`${styles["nft-utility-heading"]} ${
                isJapanese ? styles["is-japanese"] : ""
              }
                  }`}
            >
              {t("utilityHeading")}
            </h3>
          </div>
          <p className={styles["nft-utility-contents"]}>
            {t("utilityContents")}
          </p>
          <div className="flex justify-center px-[16px] mt-[16px]">
            <UtilityImg />
          </div>
        </section>
      )}

      {/** GALLERY */}
      <Link href="/gallery" passHref>
        <a>
          <div
            className={`flex justify-between mt-[148px] ${
              isMobile ? "mb-[10px]" : "items-center mb-[34px]"
            }`}
          >
            <div className={styles["gallery-section-top-border"]} />
            <div className={isMobile ? "" : "mr-[32px]"}>
              <Image
                src="/images/decoration/genre_cross.png"
                alt="cross"
                width={isMobile ? 80 : 182}
                height={isMobile ? 16 : 34}
              />
            </div>
          </div>
          <section
            className={`${styles["section-wrapper"]} ${styles["section-gallery"]}`}
          >
            <div className={styles["inner-gallery"]}>
              <div className={styles["home-gallery"]}>
                <div className={styles["home-gallery-image"]}>
                  <Image
                    src="/images/text_images/GENE_Bg_decoration.png"
                    alt="decoration"
                    width={isMobile ? 312 : 624}
                    height={isMobile ? 125 : 250}
                  />
                </div>
                <AutoSwiper items={items} />
              </div>
              <div
                className={`flex justify-between items-center ${
                  isMobile ? "mt-[9px]" : "mt-[25px]"
                }`}
              >
                <div className={isMobile ? "" : "ml-[32px]"}>
                  <Image
                    src="/images/decoration/genre_barcode.png"
                    alt="barcode"
                    width={isMobile ? 100 : 200}
                    height={isMobile ? 17 : 34}
                  />
                </div>
                <div className={styles["gallery-section-top-border"]} />
              </div>
            </div>
          </section>
        </a>
      </Link>

      <div
        className={isMobile ? "mt-[41px] mb-[14.2px]" : "mt-[96px] mb-[64.2px]"}
      >
        <VerticalDots />
      </div>

      <section
        id="commingSoon"
        className={`mx-auto ${
          isMobile ? "mb-[93px] overflow-x-hidden" : "mb-[120px]"
        }`}
      >
        <div className={`text-center`}>
          {isMobile ? (
            <div className="min-w-[524px] mx-[calc(50vw_-_262px)]">
              <Image
                src="/images/text_images/coming_soon_sp.png"
                alt="Coming Soon"
                width={524}
                height={161}
              />
            </div>
          ) : (
            <Image
              src="/images/text_images/coming_soon.png"
              alt="Coming Soon"
              width={701}
              height={141}
            />
          )}
        </div>
      </section>
    </div>
  );
};

const GradientRect = ({ extraClass }: { extraClass: string }) => (
  <div className={`${styles["gradient-rect"]} ${extraClass}`} />
);

const TRANSITION_TIMEOUT_MS = 1000;
const UtilityImg = () => {
  const [showsInitial, setShowsInitial] = useState(true);

  return (
    <div
      className="relative w-[536px] h-[350px] cursor-pointer"
      onClick={() => setShowsInitial((prev) => !prev)}
    >
      <Transition in={showsInitial} timeout={TRANSITION_TIMEOUT_MS}>
        {(state) => (
          <div
            className="absolute z-10 [transition:transform_0.2s_linear,opacity_0.1s_linear_0.1s]"
            style={{
              ...(
                {
                  entering: { opacity: 1, transform: "none" },
                  entered: { opacity: 1, transform: "none" },
                  exiting: { opacity: 0, transform: "scale(0.045, 0.069)" },
                  exited: { opacity: 0, transform: "scale(0.045, 0.069)" },
                } as any
              )[state],
            }}
          >
            <Image
              src="/images/decoration/utility_thumb.png"
              alt="Utility Thumb"
              width={536}
              height={350}
            />
          </div>
        )}
      </Transition>
      <div className="absolute">
        <Image
          src="/images/decoration/utility_thumb_folder.png"
          alt="Utility Thumb"
          width={536}
          height={350}
        />
      </div>
    </div>
  );
};

export default Home;
