import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useMedia } from "react-use";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { MOBILE_MAX_WIDTH } from "../../../constant";
import { dateFormat } from "../../../services/dateFormat";
import { NewsArray } from "../../../types/newsType";

import PrimaryAnimatedBtn from "../../common/ui/PrimaryAnimatedBtn";
import styles from "./newsListSwipe.module.scss";

type Props = {
  newsArray: NewsArray;
};

const MAX_SHOWN_LENGTH = 53;
const ELLIPSIS = "...";
const ELLIPSIS_LENGTH = ELLIPSIS.length;

const truncate = (s: string): string =>
  s.length <= MAX_SHOWN_LENGTH
    ? s
    : s.slice(0, MAX_SHOWN_LENGTH - ELLIPSIS_LENGTH) + ELLIPSIS;

const NewsListSwipe = ({ newsArray }: Props) => {
  // mq
  const isMobile = useMedia(`(max-width: ${MOBILE_MAX_WIDTH}px)`, false);

  const [isClient, setIsClient] = useState(false);

  SwiperCore.use([Autoplay]);

  // react-hydration-error 対応
  useEffect(() => setIsClient(true), []);

  return (
    <>
      {isClient && (
        <>
          {isMobile && (
            <div className={styles["news-module"]}>
              <div className={`text-center`}>
                <Image
                  src={"/images/text_images/news.png"}
                  alt="News Font"
                  width={537}
                  height={108}
                />
              </div>
              <div className={`swiper-prev ${styles["news-prev"]}`}>
                <Image
                  src="/images/button_images/arrow_left.png"
                  alt="left"
                  width={30.15}
                  height={60}
                />
              </div>
              <div className={`swiper-next ${styles["news-next"]}`}>
                <Image
                  src="/images/button_images/arrow_right.png"
                  alt="right"
                  width={30.15}
                  height={60}
                />
              </div>
              <div className={styles["sp-news-wrapper"]}>
                <div className={styles["upper-news-frame"]}>
                  <div className="bg-cover bg-center">
                    <Image
                      src="/images/decoration/news_frame.png"
                      width={50}
                      height={50}
                      alt="news_frame 1"
                    />
                  </div>
                  <div className="flex items-start justify-center">
                    <div className="bg-white h-1 w-1 rounded-full"></div>
                  </div>
                  <div className="bg-cover bg-center flex justify-end">
                    <Image
                      src="/images/decoration/news_frame.png"
                      width={53}
                      height={53}
                      className="rotate-90"
                      alt="news_frame 2"
                    />
                  </div>
                </div>

                <Swiper
                  dir="ltl"
                  modules={[Navigation, Pagination]}
                  slidesPerView={1}
                  spaceBetween={40}
                  loop={true}
                  allowTouchMove={true}
                  navigation={{
                    prevEl: ".swiper-prev",
                    nextEl: ".swiper-next",
                  }}
                  pagination={{
                    el: "#news-swiper-pagination",
                    clickable: true,
                  }}
                >
                  {newsArray.map((news) => {
                    return (
                      <SwiperSlide key={news.id}>
                        <Link key={news.id} href={`/news/${news.id}`}>
                          <div>
                            <div className={styles["news-card"]}>
                              <div className="w-[309px] h-[207px] relative">
                                <Image
                                  src={
                                    news.eyecatch?.url ??
                                    "/images/decoration/news_thumb.png"
                                  }
                                  alt="News Thumb"
                                  layout="fill"
                                  objectFit="cover"
                                  objectPosition="top"
                                />
                              </div>
                              <div className={styles["news-card-text-area"]}>
                                <p className="text-[12px] heading-[100%] font-bold mb-[4px]">
                                  {dateFormat(news.publishedAt)}
                                </p>
                                <p className="text-[16px] heading-[150%] font-bold">
                                  {truncate(news.title)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>

                <div className={styles["lower-news-frame"]}>
                  <div className="bg-cover bg-center">
                    <Image
                      src="/images/decoration/news_frame.png"
                      alt="news_frame 3"
                      width={50}
                      height={50}
                      className="-rotate-90"
                    />
                  </div>
                  <div className="flex items-end justify-center">
                    <div className="bg-white h-1 w-1 rounded-full"></div>
                  </div>
                  <div className="bg-cover bg-center">
                    <Image
                      src="/images/decoration/news_frame.png"
                      width={50}
                      height={50}
                      className="rotate-180"
                      alt="news_frame 4"
                    />
                  </div>
                </div>
              </div>
              <div
                id="news-swiper-pagination"
                className="!w-[50%] !mx-auto mt-[11px] mb-[36px] grid justify-center justify-items-center grid-cols-3"
              />
              <div className="w-full grid justify-center justify-items-center grid-cols-1">
                <PrimaryAnimatedBtn href="/news/page/1" extraClass="w-[312px]">
                  VIEW MORE
                </PrimaryAnimatedBtn>
              </div>
            </div>
          )}

          {!isMobile && (
            <section id="news" className="mx-auto overflow-x-hidden">
              <div className={`text-center`}>
                <Image
                  src={"/images/text_images/news.png"}
                  alt="News Font"
                  width={701}
                  height={141}
                />
              </div>
              <div className="max-w-[1364px] max-h-screen mx-auto">
                <div
                  className="bg-white h-1 w-1 rounded-full mr-auto relative max-h-[calc(100vh-10rem)]"
                  style={{ top: `8rem` }}
                />
                <div
                  className="bg-white h-1 w-1 rounded-full ml-auto relative max-h-[calc(100vh-10rem)]"
                  style={{ top: `8rem` }}
                />
                <div className="grid grid-cols-3 gap-4 h-10 justify-center">
                  <div className="bg-cover bg-center">
                    <Image
                      src="/images/decoration/news_frame.png"
                      alt="news_frame 1"
                      width={36}
                      height={36}
                    />
                  </div>
                  <div className="flex items-start justify-center">
                    <div className="bg-white h-1 w-1 rounded-full"></div>
                  </div>
                  <div className="bg-cover bg-center flex justify-end">
                    <Image
                      src="/images/decoration/news_frame.png"
                      alt="news_frame 2"
                      width={36}
                      height={36}
                      className="rotate-90"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 mt-[-18px] items-center justify-center h-[397px]">
                  {newsArray.map((news) => (
                    <Link key={news.id} href={`/news/${news.id}`}>
                      <div className="justify-self-center">
                        <div className={styles["news-card"]}>
                          <div className="w-[418px] h-[232px] relative">
                            <Image
                              src={
                                news.eyecatch?.url ??
                                "/images/decoration/news_thumb.png"
                              }
                              alt="News Thumb"
                              layout="fill"
                              objectFit="cover"
                              objectPosition="top"
                            />
                          </div>
                          <div className={styles["news-card-text-area"]}>
                            <p className="text-[14px] heading-[14px] font-bold">
                              {dateFormat(news.publishedAt)}
                            </p>
                            <p className="text-[20px] heading-[30px] font-bold">
                              {truncate(news.title)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
                <div className="grid grid-cols-3 gap-4 h-10 justify-center mt-[-18px]">
                  <div className="bg-cover bg-center">
                    <Image
                      src="/images/decoration/news_frame.png"
                      alt="news_frame 3"
                      width={36}
                      height={36}
                      className="-rotate-90"
                    />
                  </div>
                  <div className="flex items-end justify-center">
                    <div className="bg-white h-1 w-1 rounded-full"></div>
                  </div>
                  <div className="bg-cover bg-end flex justify-end">
                    <Image
                      src="/images/decoration/news_frame.png"
                      alt="news_frame 4"
                      width={36}
                      height={36}
                      className="rotate-180"
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-[48px]">
                <PrimaryAnimatedBtn href="/news/page/1" extraClass="w-[312px]">
                  VIEW MORE
                </PrimaryAnimatedBtn>
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
};

export default NewsListSwipe;
