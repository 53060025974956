import { DateZoneType } from "../types/zoneType";

// タイムスタンプを日時の表記で返す
export const dateTimeFormat = (
  timestamp: number,
  zone: DateZoneType
): string => {
  // zone が universal の時
  let date = new Date(timestamp);

  if (zone === "JST") {
    date = new Date(
      timestamp + (new Date().getTimezoneOffset() + 9 * 60) * 60 * 1000
    );
  }

  // 時間のフォーマット
  let hours = zone === "JST" ? date.getHours() : date.getUTCHours();
  const minutes = zone === "JST" ? date.getMinutes() : date.getUTCMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  if (hours != 0 && hours != 12) {
    hours = hours % 12;
  }

  // 日のフォーマット
  const year = zone === "JST" ? date.getFullYear() : date.getUTCFullYear();
  const month = zone === "JST" ? date.getMonth() + 1 : date.getUTCMonth() + 1;
  const day = zone === "JST" ? date.getDate() : date.getUTCDate();

  const strTime = `${hours}:${String(minutes).padStart(
    2,
    "0"
  )} ${ampm} ${String(day).padStart(2, "0")}/${String(month).padStart(
    2,
    "0"
  )}/${year}`;

  return strTime;
};

export const dateFormat = (timestamp: number | string): string => {
  const d = new Date(timestamp);
  return d.toLocaleDateString();
};
