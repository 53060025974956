import styles from "./index.module.scss";

const VerticalDots = () => (
  <div className={styles["dots-wrapper"]}>
    <Dot />
    <Dot />
    <Dot />
  </div>
);

const Dot = () => <div className={styles["dot"]} />;

export default VerticalDots;
