import Image from "next/image";
import { useEffect, useState } from "react";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./index.module.scss";

type Props = {
  items: { src: string; text: string }[];
};

/** LP Auto Swiper */
export const AutoSwiper = ({ items }: Props) => {
  const [isClient, setIsClient] = useState(false);

  SwiperCore.use([Autoplay]);

  // react-hydration-error 対応
  useEffect(() => setIsClient(true), []);

  return (
    <>
      {isClient && (
        <Swiper
          className=""
          slidesPerView={2.2}
          spaceBetween={2}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
          }}
          speed={4000}
          loop={true}
          loopAdditionalSlides={1}
          breakpoints={{
            500: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
        >
          {items.map((i, index) => {
            return (
              <SwiperSlide key={index}>
                <div className={styles.item}>
                  <Image src={i.src} alt="item" layout="fill" />
                </div>
                <p className={styles["item-text"]}>GENE N_XXXX</p>
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </>
  );
};
